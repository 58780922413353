// Trainer Selection api
export const traineeSelectionBaseUrl = '/tim/trainee-selections/'
export const traineeSelectionList = traineeSelectionBaseUrl + 'list'
export const traineeSelectionStore = traineeSelectionBaseUrl + 'store'
export const traineeIdCards = '/tim/trainee-id-cards'

// Attendance api
export const attendanceBaseUrl = '/tim/attendances/'
export const applicationWiseBatchList = attendanceBaseUrl + '/application-wise-batch-list'
export const attendanceList = attendanceBaseUrl + 'list'
export const attendanceStore = attendanceBaseUrl + 'store'
export const attendanceUpdate = attendanceBaseUrl + 'update'
export const attendanceShow = attendanceBaseUrl + 'show'
export const attendanceToggleStatus = attendanceBaseUrl + 'toggle-status'
export const attendancePersonalInfoList = attendanceBaseUrl + 'personal-info-list'
export const batchWiseApplicationList = attendanceBaseUrl + 'circular-wise-attendance'

// Allowance api
export const allowanceBaseUrl = '/tim/allowance/'
export const batchWiseApplicationAttendanceList = allowanceBaseUrl + 'batch-wise-attendance'

// Bill Payment api
export const billPaymentBaseUrl = '/tim/bill-payment/'
export const billPaymentList = billPaymentBaseUrl + 'list'
export const billPaymentHonorarumList = billPaymentBaseUrl + 'honorarum-list'
export const billPaymentHonorarumDetails = billPaymentBaseUrl + 'honorarum-list-details'
export const billPaymentHonorarumMaterials = '/config/training-title/material-list'
export const billPaymentPaymentSearch = billPaymentBaseUrl + 'payment-search'
export const billPaymentToggleStatus = billPaymentBaseUrl + 'toggle-status'
export const billPaymentStore = billPaymentBaseUrl + 'store'
export const billPaymentShow = billPaymentBaseUrl + 'show'
export const billPaymentFile = billPaymentBaseUrl + 'attachment'
export const billPaymentFinalSave = billPaymentBaseUrl + 'final-save'
export const billGeneratePaymentTranerTrinee = '/training-e-learning-service-payment/payment-tranee-or-tranier'
export const billGeneratePaymentUpdate = '/training-e-learning-service-payment/'

// Evaluation api
export const evaluationBaseUrl = '/tim/evaluations/'
export const evaluationList = evaluationBaseUrl + 'list'
export const evaluationStore = evaluationBaseUrl + 'store'
export const evaluationUpdate = evaluationBaseUrl + 'update'
export const evaluationShow = evaluationBaseUrl + 'show'
export const evaluationToggleStatus = evaluationBaseUrl + 'toggle-status'

// Evaluation api
export const courseEvaluationBaseUrl = '/tim/course-evaluations/'
export const courseEvaluationList = courseEvaluationBaseUrl + 'list'
export const courseEvaluationStore = courseEvaluationBaseUrl + 'store'
export const courseEvaluationUpdate = courseEvaluationBaseUrl + 'update'
export const courseEvaluationShow = courseEvaluationBaseUrl + 'show'
export const courseEvaluationApprove = courseEvaluationBaseUrl + 'approve'

// Disciplinary Action api
export const disciplinaryBaseUrl = '/tim/disciplinary-action/'
export const disciplinaryActionList = disciplinaryBaseUrl + 'list'
export const disciplinaryActionStore = disciplinaryBaseUrl + 'store'
export const disciplinaryActionUpdate = disciplinaryBaseUrl + 'update'
export const disciplinaryActionShow = disciplinaryBaseUrl + 'show'
export const disciplinaryActionToggleStatus = disciplinaryBaseUrl + 'toggle-status'
export const trainingApplicationBaseUrl = '/iabm/training-applications/'
export const trainingApplicationPersonalInfo = trainingApplicationBaseUrl + 'personal-info'
export const trainingApplicationCircularPublicationInfo = trainingApplicationBaseUrl + 'circular-publication-info'
export const trainingApplicationAllList = trainingApplicationBaseUrl + 'application-list'
export const applicationForBatchList = trainingApplicationBaseUrl + 'application-batch-list'
export const trainingApplicationBatchList = trainingApplicationBaseUrl + 'batch-list'
export const trainingApplicationBatchShow = trainingApplicationBaseUrl + 'batch-show'

// Trainee Evaluation api
export const traineeEvaluationBaseUrl = '/tim/trainee-evaluations/'
export const traineeEvaluationList = traineeEvaluationBaseUrl + 'list'
export const traineeEvaluationStore = traineeEvaluationBaseUrl + 'store'
export const traineeEvaluationUpdate = traineeEvaluationBaseUrl + 'update'
export const traineeEvaluationShow = traineeEvaluationBaseUrl + 'show'
export const traineeEvaluationToggleStatus = traineeEvaluationBaseUrl + 'toggle-status'
export const traineeEvaluationMarkInfo = traineeEvaluationBaseUrl + 'evaluation-mark-info'

export const trainingApplicationListPaginate = '/iabm/booking/application'
export const personalInfoList = '/iabm/personal-infos/application-list'

// Nominated Trainee Trainer
export const traineeTrainerApplication = '/tim/nominated-trainee-trainer/application-list'

// Trainer Sorting api
export const trainerSortingBaseUrl = '/tpm/trainer-sorting/'
export const trainerSortingList = trainerSortingBaseUrl + 'list'
export const trainerSortingStore = trainerSortingBaseUrl + 'store'
export const trainerSortingBatchStore = trainerSortingBaseUrl + 'batch-store'
export const nominatedStore = trainerSortingBaseUrl + 'nominated-store'
export const trainingScheduleBaseUrl = '/tpm/training-schedule/'
export const trainingModuleWiseTrainer = trainingScheduleBaseUrl + 'module-wise-trainer'

export const trainerEvaluationBaseUrl = '/tim/trainer-evaluations/'
export const trainerEvaluationList = trainerEvaluationBaseUrl + 'list'
export const trainerEvaluationDetails = trainerEvaluationBaseUrl + 'details'
export const trainerWiseEvaluationList = '/tim/trainer-wise-evaluations/list'

export const trainingPublicationBaseUrl = '/iabm/circular-publications/'
export const circularPublicationList = trainingPublicationBaseUrl + 'circular-publication-list'
export const circularPublication = trainingPublicationBaseUrl + 'circular-publication-data'
export const circularPublicationDate = trainingPublicationBaseUrl + 'circular-publication-date'

// Other Selection api
export const otherTrainingApplicationsBaseUrl = '/iabm/other-training-applications/'
export const otherTrainingApplicationsList = otherTrainingApplicationsBaseUrl + 'list'
export const otherTrainingApplicationsStore = otherTrainingApplicationsBaseUrl + 'store'

// Other Selection api
export const manualCourseEvaluationBaseUrl = '/tim/manual-course-evaluation/'
export const manualStoreCourseEvaluation = manualCourseEvaluationBaseUrl + 'store'
export const manualStoreCourseEvaluationList = manualCourseEvaluationBaseUrl + 'list'
export const manualStoreCourseEvaluationShow = manualCourseEvaluationBaseUrl + 'show'
export const manualCourseEvaluation = manualCourseEvaluationBaseUrl + 'manual-course-evaluation'

// Attendance api
export const certificateBaseUrl = '/tim/certificate/'
export const certificateList = certificateBaseUrl + 'list'
export const certificateIndividualList = certificateBaseUrl + 'certificate'
export const certificateIndividualShow = certificateBaseUrl + 'certificateShow'
export const certificateIndividualPdf = certificateBaseUrl + 'certificatePdf'
export const certificateDetails = certificateBaseUrl + 'show'
export const certificateApplicant = certificateBaseUrl + 'applicant-list'
export const certificateStore = certificateBaseUrl + 'store'
export const certificateDetailsStore = certificateBaseUrl + 'store-description'
export const certificateDetailsUpdate = certificateBaseUrl + 'update-description'
export const certificateDetail = certificateBaseUrl + 'show-details'

export const reportHeadingListNew = '/config/report-head/listnew'
// circular memo list
export const circularMemoList = '/iabm/circular-publications/' + 'circular-publication-list'
export const circularData = '/iabm/circular-publications/' + 'circular-publication-data'
// Complains api
export const complainList = '/tim/complain/list'
export const complainStore = '/tim/complain/store'
export const complainUpdate = '/tim/complain/update'
export const complainToggleStatus = '/tim/complain/toggle-status'
export const complainResolve = '/tim/complain/approve'
export const profileInfo = '/tim/complain/profile-info'
export const complaindetails = '/tim/complain/complain-details'
// Trainee Evaluation
export const TrainerSpecificTrainingApplicationList = '/external-user/booking/application'
export const batchWiseTrainee = '/external-user/pre-course-evaluations/batch-wise-trainee'
