<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">
          {{ $t('globalTrans.honour_amount') }} {{ $t('globalTrans.insert') }}
        </h4>
      </template>
      <template v-slot:searchHeaderAction>
        <router-link to="bill-payment" class="btn-add"><i class="ri-arrow-left-line"></i> {{ $t('globalTrans.honour_amount') }} {{ $t('globalTrans.list') }}</router-link>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="6" sm="6">
                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="6">
                <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="circular_memo_no"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.circular_memo_no"
                        :options="circularList"
                        id="circular_memo_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-overlay :show="load">
              <b-row>
                <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="formData.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Batch" vid="batch_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="batch_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.batch_id"
                        :options="batchList"
                        id="batch_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Honour Amount" vid="honour_amount">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="honour_amount"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.honour_amount')}}
                      </template>
                      <b-form-input
                        v-model="formData.honour_amount"
                        :placeholder="$t('globalTrans.honour_amount')"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Tax" vid="vat_tax">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="vat_tax"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.income_tax') + '(%)'}}
                      </template>
                      <b-form-input
                        v-model="formData.vat_tax"
                        :placeholder="$t('globalTrans.income_tax')"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Date" vid="date">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="date"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.select_date')}}
                      </template>
                      <b-form-input
                        class="fromDate"
                        v-model="formData.payment_date"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-overlay>
            <body-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('globalTrans.honour_amount')}}</h4>
              </template>
              <template v-slot:body>
                <row>
                  <div class="col-md-12">
                    <b-overlay :show="loading">
                      <b-table-simple hover small responsive bordered>
                        <b-tr>
                          <b-th class="text-center" style="width:7%">{{$t('globalTrans.sl_no')}}</b-th>
                          <b-th style="width:12%">{{$t('globalTrans.name')}}</b-th>
                          <b-th style="width:12%">{{$t('globalTrans.designation')}}</b-th>
                          <b-th style="width:7%">{{$t('elearning_tim.number_of_class')}}</b-th>
                          <b-th style="width:12%">{{$t('globalTrans.honour_amount')}}</b-th>
                          <b-th style="width:7%">{{$t('globalTrans.total_amount')}}</b-th>
                          <b-th style="width:7%">{{$t('globalTrans.income_tax_amount')}}</b-th>
                          <b-th style="width:7%">{{$t('globalTrans.detuction')}}</b-th>
                          <b-th style="width:10%">{{$t('globalTrans.net_amount')}}</b-th>
                        </b-tr>
                        <b-tr v-for="(train,index) in formData.payments" :key="train.id">
                          <b-td class="text-center">{{$n(index+1)}}</b-td>
                          <b-td>
                            <div style="font-size: 14px;">
                              {{ ($i18n.locale==='bn') ? train.speaker_bn : train.speaker }}
                            </div>
                          </b-td>
                          <b-td>
                            <div style="font-size: 14px;">
                              {{ ($i18n.locale==='bn') ? train.designation_bn : train.designation_en }}
                            </div>
                          </b-td>
                          <b-td>
                            <b-form-input
                              rows="2"
                              v-model="train.total_days"
                              :placeholder="$t('elearning_tim.number_of_class')"
                              @input="paymentAmountUpdate(train)"
                            ></b-form-input>
                            <small v-if="paymentErrors && paymentErrors['payments.' + index + '.total_days']" class="text-danger">
                              {{ paymentErrors['payments.'+index+'.total_days'] }}
                            </small>
                          </b-td>
                          <b-td>
                            <b-form-input
                              rows="2"
                              v-model="train.honour_amount"
                              :placeholder="$t('elearning_tim.allowance')"
                              @input="paymentAmountUpdate(train)"
                            ></b-form-input>
                            <small v-if="paymentErrors && paymentErrors['payments.' + index + '.honour_amount']" class="text-danger">
                              {{ paymentErrors['payments.'+index+'.honour_amount'] }}
                            </small>
                          </b-td>
                          <b-td>{{train.total_days * train.honour_amount}}</b-td>
                          <b-td>
                            {{train.vat_tax_amount}}
                          </b-td>
                          <b-td>
                            <b-form-input
                              rows="2"
                              v-model="train.other"
                              :placeholder="$t('globalTrans.detuction')"
                              @input="paymentAmountUpdate(train)"
                            ></b-form-input>
                            <small v-if="paymentErrors && paymentErrors['payments.' + index + '.other']" class="text-danger">
                              {{ paymentErrors['payments.'+index+'.other'] }}
                            </small>
                          </b-td>
                          <b-td>
                            <b-form-input
                              v-model="train.payment_amount"
                              :placeholder="$t('globalTrans.payment_amount')"
                              readonly
                            ></b-form-input>
                            <small v-if="paymentErrors && paymentErrors['payments.' + index + '.payment_amount']" class="text-danger">
                              {{ paymentErrors['payments.'+index+'.payment_amount'] }}
                            </small>
                          </b-td>
                        </b-tr>
                        <template v-if="formData.payments.length === 0">
                          <b-tr>
                            <b-th colspan="10" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</b-th>
                          </b-tr>
                        </template>
                      </b-table-simple>
                    </b-overlay>
                  </div>
                </row>
              </template>
            </body-card>
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col text-right">
                <b-button type="submit" variant="success" class="mr-1 btn-sm">{{ saveBtnName }}</b-button>
                  &nbsp;
                  <router-link to="bill-payment" class="mr-1 btn btn-danger btn-sm">{{ $t('globalTrans.cancel') }}</router-link>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </card>
  </div>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentStore, billPaymentPaymentSearch, circularPublication, circularPublicationList } from '../../api/routes'
import flatpickr from 'flatpickr'
const today = new Date().toISOString().substr(0, 10)

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.getCircularList()
    if (this.$route.query.id) {
      this.getFormData()
    }
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.currentFiscalYearId
    })
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      today: today,
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        payment_type: 'Trainer',
        fiscal_year_id: 0,
        org_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        batch_id: 0,
        circular_memo_no: 0,
        vat_tax: 0,
        honour_amount: 0,
        payment_date: '',
        training_status: 1,
        trainer_payment: 1,
        payments: []
      },
      payment: [],
      batchList: [],
      circularList: [],
      circularLoading: false,
      required: true,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainingTitleList: [],
      scheduleList: [],
      paymentErrors: []
    }
  },
  computed: {
    // loading: function () {
    //     return this.$store.state.commonObj.loading
    //   },
    trainerList: function () {
      const listObject = this.trainerListData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_en }
        } else {
          return { value: obj.value, text: obj.text_bn }
        }
      })
      return tmpList
    },
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    paymentType: function () {
      return this.$store.state.TrainingElearning.commonObj.paymentType
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.batchList = this.getBatchList(newValue)
    },
    'formData.fiscal_year_id': function (newValue) {
      this.getCircularList()
    },
    'formData.batch_id': function (newValue) {
      this.getTrainerSheduleList()
    },
    'formData.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'formData.honour_amount': function (newValue, oldVal) {
      if (newValue !== oldVal) {
        this.vatAddAll()
      }
    },
    'formData.vat_tax': function (newValue, oldVal) {
      if (newValue !== oldVal) {
        this.vatAddAll()
      }
    },
    'formData.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    getBatchList (orgId) {
      const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
      if (orgId) {
        return batchList.filter(item => item.org_id === orgId)
      }
      return batchList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.formData.fiscal_year_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
         const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    async getCircularMemoNo () {
      this.load = true
      const params = {
        circular_memo_no: this.formData.circular_memo_no,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      if (!result.success) {
        this.formData.fiscal_year_id = 0
        this.formData.org_id = 0
        this.formData.training_title_id = 0
        this.formData.training_type_id = 0
        this.formData.honour_amount = 0
        this.formData.vat_tax = 0
        // this.formData.payment_date = 0
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.formData.fiscal_year_id = trData.fiscal_year_id
        this.formData.org_id = trData.org_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.training_type_id = trData.training_type_id
        // this.formData.honour_amount = trData.honour_amount
        // this.formData.vat_tax = trData.vat_tax
        // this.formData.payment_date = trData.payment_date
        // this.getTrainerSheduleList()
      }
      this.load = false
    },
    vatAddAll () {
      const payments = this.formData.payments.map(item => {
        const amountData = {}
        item.vat_tax = this.formData.vat_tax
        item.honour_amount = this.formData.honour_amount
        const paymentCalculate = this.paymentAmountUpdate(item)
        amountData.vat_tax_amount = paymentCalculate.vat_tax_amount
        amountData.payment_amount = paymentCalculate.payment_amount
        return Object.assign({}, item, amountData)
      })
      this.formData.payments = payments
    },
    paymentAmountUpdate (train) {
      const amount = parseFloat(train.honour_amount) * parseFloat(train.total_days)
      train.vat_tax_amount = this.calculatePercentage(amount, train.vat_tax)
      train.payment_amount = (parseFloat(amount) - parseFloat(train.vat_tax_amount) - parseFloat(train.other)) - parseFloat(train.detucton)
      return train
    },
    calculatePercentage (oldAvg, newAvg) {
      const totalPercentage = (parseFloat(oldAvg) * newAvg) / 100
      return totalPercentage
    },
    removeAttachment (index, data) {
      this.formData.module_attachments.splice(index, 1)
      if (data.pre_attachment) {
        this.formData.module_attachments_remove.push(data)
      }
    },
    getTrainingTitleList (typeId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (typeId) {
        return trainingTitleList.filter(item => item.training_type_id === typeId)
      }
      return trainingTitleList
    },
    async getTrainerSheduleList () {
      this.loading = true
      const data = this.formData
      data.payments = []
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentPaymentSearch, data)
      if (!result.success) {
        this.formData.payments = []
        this.loading = false
        this.required = true
        if (result.success) {
          if (result.grade) {
            this.$toast.success({
              title: 'Success',
              message: result.message,
              color: '#D6E09B'
            })
          }
        } else {
          this.$toast.error({
            title: 'Error',
            message: this.$t('elearning_tim.no_grade_found'),
            color: '#D6E09B'
          })
        }
      } else {
        const resultData = result.data
        this.payment = result.payment
        if (result.main) {
          this.formData.honour_amount = result.main.honour_amount
          this.formData.payment_date = result.main.payment_date
          this.formData.vat_tax = result.main.vat_tax
        }
        this.scheduleList = result.scheduleList
        const payments = []
        resultData.map(item => {
          const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const paymentObj = this.payment.find(payment => payment.batch_id === parseInt(item.batch_id) && payment.training_title_id === parseInt(item.training_title_id))
          const paymentData = {}
          paymentData.batch_id = item.batch_id
          const scheduleListObjData = this.scheduleList.filter(scheduleListObj => scheduleListObj.trainer_id === parseInt(item.trainer_id))
          if (typeof scheduleListObjData !== 'undefined') {
            paymentData.total_days = scheduleListObjData.length
            if (typeof paymentObj !== 'undefined') {
              paymentData.id = paymentObj.id
              paymentData.honour_amount = paymentObj.honour_amount
              paymentData.vat_tax = paymentObj.vat_tax
              paymentData.vat_tax_amount = paymentObj.vat_tax_amount
              paymentData.other = paymentObj.other
              paymentData.detucton = paymentObj.detucton
              paymentData.payment_amount = paymentObj.payment_amount
              paymentData.payment_date = paymentObj.payment_date
              paymentData.comments = paymentObj.comments
              let disbledPayment = false
              if (paymentObj.payment_status === 2) {
                disbledPayment = true
              }
              paymentData.payment_disabled = disbledPayment
              this.required = false
            } else {
              paymentData.id = 0
              paymentData.vat_tax = 0
              paymentData.vat_tax_amount = 0
              paymentData.other = 0
              paymentData.detucton = 0
              paymentData.payment_amount = 0
              paymentData.comments = ''
              this.formData.payment_date = this.today
              paymentData.payment_disabled = false
            }
            const checkData = Object.assign({}, item, paymentData, orgData)
            payments.push(checkData)
          }
        })
        this.formData.payments = payments
        this.loading = false
      }
    },
    async createData () {
      this.loading = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, billPaymentStore, this.formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.getTrainerSheduleList()
        this.$router.push('/training-e-learning-service/tim/bill-payment')
      } else {
        this.paymentErrors = result.errors
        this.$refs.form.setErrors(result.errors)
        // this.$refs.form.setErrors(result.errors)
      }
  }
  }
}
</script>
